export const EmailIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.53613 4H20.5361C21.6361 4 22.5361 4.9 22.5361 6V18C22.5361 19.1 21.6361 20 20.5361 20H4.53613C3.43613 20 2.53613 19.1 2.53613 18V6C2.53613 4.9 3.43613 4 4.53613 4Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.5361 6L12.5361 13L2.53613 6"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
