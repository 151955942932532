export const MenuIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="1em"
      height="1em"
      fill="currentColor"
      {...props}
    >
      <path d="M22 13H2C1.73478 13 1.48043 12.8946 1.29289 12.7071C1.10536 12.5196 1 12.2652 1 12C1 11.7348 1.10536 11.4804 1.29289 11.2929C1.48043 11.1054 1.73478 11 2 11H22C22.2652 11 22.5196 11.1054 22.7071 11.2929C22.8946 11.4804 23 11.7348 23 12C23 12.2652 22.8946 12.5196 22.7071 12.7071C22.5196 12.8946 22.2652 13 22 13Z" />
      <path d="M22 5H2C1.73478 5 1.48043 4.89464 1.29289 4.70711C1.10536 4.51957 1 4.26522 1 4C1 3.73478 1.10536 3.48043 1.29289 3.29289C1.48043 3.10536 1.73478 3 2 3H22C22.2652 3 22.5196 3.10536 22.7071 3.29289C22.8946 3.48043 23 3.73478 23 4C23 4.26522 22.8946 4.51957 22.7071 4.70711C22.5196 4.89464 22.2652 5 22 5Z" />
      <path d="M22 21H2C1.73478 21 1.48043 20.8946 1.29289 20.7071C1.10536 20.5196 1 20.2652 1 20C1 19.7348 1.10536 19.4804 1.29289 19.2929C1.48043 19.1054 1.73478 19 2 19H22C22.2652 19 22.5196 19.1054 22.7071 19.2929C22.8946 19.4804 23 19.7348 23 20C23 20.2652 22.8946 20.5196 22.7071 20.7071C22.5196 20.8946 22.2652 21 22 21Z" />
    </svg>
  );
};
