import { AlertDialog, type CSS } from '@paid-ui/ui';
import { forwardRef, useCallback, useMemo } from 'react';
import { useSnapshot } from 'valtio/react';

import { alertDialogManager, closeAlertDialog } from './model';

export const GlobalAlertDialog = forwardRef<HTMLDivElement>((props, ref) => {
  const { open, details } = useSnapshot(alertDialogManager);
  const {
    title,
    description,
    customIcon,
    closeOnEscapeKeyDown,
    confirmLoading,
    showCloseIcon,
    showAlertIcon,
    customIconStyle,
    buttonText,
    buttonProps,
    okCallback,
    closeCallback,
    dialogStyle,
  } = details;

  const handleDialogChange = useCallback(() => {
    closeAlertDialog();
    setTimeout(() => {
      if (typeof closeCallback === 'function') {
        closeCallback();
      }
    }, 150);
  }, [closeCallback]);

  return (
    <AlertDialog
      nested
      ref={ref}
      open={open}
      title={title}
      description={description}
      confirmLoading={confirmLoading}
      showAlertIcon={showAlertIcon}
      showCloseIcon={showCloseIcon}
      customIcon={customIcon}
      onOpenChange={handleDialogChange}
      onOk={okCallback}
      buttonText={buttonText}
      buttonProps={buttonProps}
      customIconStyle={customIconStyle}
      closeOnEscapeKeyDown={closeOnEscapeKeyDown}
      css={dialogStyle as CSS}
    />
  );
});

GlobalAlertDialog.displayName = 'GlobalAlertDialog';
