import { retentionAccountManager, saveRetentionAccount } from '@paid-ui/models/retention-account';
import { type AccountSummary } from '@paid-ui/types/payments';
import { request } from '@paid-ui/utils/axios';
import { useQuery } from '@tanstack/react-query';
import { usePathname } from 'next/navigation';
import { useEffect, useRef } from 'react';
import { useSnapshot } from 'valtio/react';

type GetRetentionAccountSummaryResponse = {
  data: AccountSummary;
} | null;

export const useRetentionAccountSetup = (enableAccount?: boolean) => {
  const mountedRef = useRef(false);
  const pathname = usePathname();
  const { bsb, accountNumber, forceUpdate } = useSnapshot(retentionAccountManager);

  const query = useQuery({
    retry: false,
    enabled: enableAccount && Boolean(bsb && accountNumber),
    refetchOnWindowFocus: pathname === '/account',
    queryKey: ['GetRetentionAccountSummary', enableAccount, bsb, accountNumber],
    queryFn: async () => {
      const { data } = await request.get<GetRetentionAccountSummaryResponse>(
        `/transaction-account/${bsb}/${accountNumber}/summary`,
      );
      return data?.data;
    },
  });

  useEffect(() => {
    if (!mountedRef.current) return;
    query.refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forceUpdate]);

  useEffect(() => {
    if (!query.data || query.isFetching) return;
    mountedRef.current = true;
    saveRetentionAccount(query.data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.data]);

  return query;
};
