import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';
import { cva, type VariantProps } from 'class-variance-authority';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

const dropdownMenuVariants = cva(
  'z-[200] rounded-md bg-white py-1 shadow-lg will-change-[opacity,transform] data-[side=bottom]:animate-slideUpAndFade data-[side=left]:animate-slideRightAndFade data-[side=right]:animate-slideLeftAndFade data-[side=top]:animate-slideDownAndFade',
  {
    variants: {
      fullScreen: {
        true: 'h-[calc(100vh-56px)] overflow-y-auto rounded-none shadow-none',
      },
    },
  },
);

export const DropdownMenuRoot = DropdownMenuPrimitive.Root;
export const DropdownMenuTrigger = DropdownMenuPrimitive.Trigger;
export const DropdownMenuPortal = DropdownMenuPrimitive.Portal;
export const DropdownMenuItem = DropdownMenuPrimitive.Item;
export const DropdownMenuSeparator = DropdownMenuPrimitive.Separator;
export const DropdownMenuLabel = DropdownMenuPrimitive.Label;
export const DropdownMenuArrow = DropdownMenuPrimitive.Arrow;
export const DropdownMenuContent = forwardRef<
  HTMLDivElement,
  DropdownMenuPrimitive.DropdownMenuContentProps & {
    fullScreen?: boolean | null;
    minWidth?: number | string;
  }
>(
  (
    {
      fullScreen,
      minWidth = fullScreen ? '100vw' : 200,
      side = 'bottom',
      sideOffset = fullScreen ? 11 : 4,
      align = 'center',
      collisionPadding = fullScreen ? 0 : 8,
      className,
      style,
      children,
      ...restProps
    },
    ref,
  ) => {
    return (
      <DropdownMenuPrimitive.Content
        ref={ref}
        side={side}
        sideOffset={sideOffset}
        align={align}
        collisionPadding={collisionPadding}
        className={twMerge(dropdownMenuVariants({ fullScreen, className }))}
        style={{ minWidth, ...style }}
        {...restProps}
      >
        {children}
      </DropdownMenuPrimitive.Content>
    );
  },
);

const dropdownMenuItemVariants = cva(
  'flex cursor-default items-center justify-between text-grey-dark no-underline outline-none data-[disabled]:cursor-not-allowed data-[disabled]:bg-transparent data-[disabled]:text-grey-tertiary',
  {
    variants: {
      compact: {
        true: 'px-4 py-2 text-sm',
        false: 'px-5 py-2.5 text-base',
      },
      danger: {
        true: 'text-pink',
      },
      hoverable: {
        true: 'cursor-pointer transition-colors duration-150 ease-linear hover:bg-grey-light hover:text-blue data-[highlighted]:text-blue-dark',
      },
      selected: {
        true: 'bg-grey-light text-blue',
      },
    },
    compoundVariants: [
      {
        danger: true,
        hoverable: true,
        className: 'data-[highlighted]:text-pink-dark',
      },
      {
        danger: true,
        selected: true,
        className: 'bg-pink-light text-pink',
      },
    ],
    defaultVariants: {
      compact: true,
      hoverable: true,
    },
  },
);

export type MenuItemType = 'item' | 'separator' | 'label';

export type MenuItem = {
  type?: MenuItemType;
  title?: React.ReactNode;
  extra?: React.ReactNode;
  value?: string;
  href?: string;
  target?: React.HTMLAttributeAnchorTarget;
  download?: boolean | string;
  danger?: boolean;
  hoverable?: boolean;
  selected?: boolean;
  disabled?: boolean;
  compact?: boolean;
  hidden?: boolean;
  className?: string;
  style?: React.CSSProperties;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  onSelect?: (event: Event) => void;
};

export interface DropdownMenuProps
  extends Omit<DropdownMenuPrimitive.DropdownMenuProps, 'children'>,
    DropdownMenuPrimitive.DropdownMenuContentProps,
    VariantProps<typeof dropdownMenuVariants>,
    Omit<React.HTMLAttributes<HTMLDivElement>, 'children' | 'dir'> {
  items?: MenuItem[];
  disabled?: boolean;
  asChild?: boolean;
  minWidth?: number | string;
  showArrow?: boolean;
}

const DropdownMenuItemComponent: React.FC<{ item: MenuItem }> = (props) => {
  const { item } = props;

  switch (item.type) {
    case 'separator': {
      return <DropdownMenuSeparator className="my-1 h-[1px] bg-grey-mid" />;
    }

    case 'label': {
      return (
        <DropdownMenuLabel className="px-4 py-1 text-sm text-grey-tertiary">
          <div>{item.title}</div>
          <div>{item.extra}</div>
        </DropdownMenuLabel>
      );
    }

    default: {
      return (
        <DropdownMenuItem
          asChild={Boolean(item.href)}
          textValue={item.value}
          disabled={item.disabled}
          onSelect={item.onSelect}
          onClick={(event) => event.stopPropagation()}
          onFocus={(event) => event.stopPropagation()}
          style={item.style}
          className={twMerge(
            dropdownMenuItemVariants({
              compact: item.compact,
              danger: item.danger && !item.disabled,
              hoverable: item.hoverable && !item.disabled,
              selected: item.selected && !item.disabled,
              className: item.className,
            }),
          )}
        >
          {item.href ? (
            <Link href={item.href} target={item.target} download={item.download}>
              <div>{item.title}</div>
              <div>{item.extra}</div>
            </Link>
          ) : (
            <>
              <div>{item.title}</div>
              <div>{item.extra}</div>
            </>
          )}
        </DropdownMenuItem>
      );
    }
  }
};

export const DropdownMenu = forwardRef<HTMLDivElement, DropdownMenuProps>(
  (
    {
      dir,
      open,
      defaultOpen,
      modal,
      onOpenChange,

      fullScreen,
      asChild = true,
      showArrow = fullScreen ? false : true,
      minWidth = fullScreen ? '100vw' : 200,

      items = [],
      disabled,
      hidden,
      className,
      style,
      children,
      ...restProps
    },
    ref,
  ) => {
    const pathname = usePathname();

    if (hidden) {
      return null;
    }

    if (disabled || items.length === 0) {
      return children as React.JSX.Element;
    }

    return (
      <DropdownMenuRoot
        dir={dir}
        modal={modal}
        open={open}
        defaultOpen={defaultOpen}
        onOpenChange={onOpenChange}
      >
        <DropdownMenuTrigger type="button" asChild={asChild}>
          {children}
        </DropdownMenuTrigger>
        <DropdownMenuPortal>
          <DropdownMenuContent
            ref={ref}
            fullScreen={fullScreen}
            minWidth={minWidth}
            className={className}
            {...restProps}
          >
            {items
              .filter((item) => !item.hidden)
              .map((item, index) => (
                <DropdownMenuItemComponent
                  key={index}
                  item={{
                    compact: !fullScreen,
                    selected: pathname !== '/' && item.href?.startsWith(pathname),
                    ...item,
                  }}
                />
              ))}
            {showArrow ? <DropdownMenuArrow className="fill-white" /> : null}
          </DropdownMenuContent>
        </DropdownMenuPortal>
      </DropdownMenuRoot>
    );
  },
);

DropdownMenu.displayName = 'DropdownMenu';
DropdownMenuContent.displayName = 'DropdownMenuContent';
