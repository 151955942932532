import { type SVGIconName } from '@paid-ui/icons';
import { type CSS } from '@paid-ui/ui';
import { type ButtonProps } from '@paid-ui/ui/button';
import { proxy } from 'valtio';

interface AlertDialogManagerDetails {
  title?: React.ReactNode;
  description?: React.ReactNode;
  customIcon?: SVGIconName;
  customIconStyle?: React.CSSProperties;
  closeOnEscapeKeyDown?: boolean;
  dialogStyle?: CSS;
  confirmLoading?: boolean;
  showCloseIcon?: boolean;
  showAlertIcon?: boolean;
  buttonText?: string;
  buttonProps?: ButtonProps;
  okCallback?: () => void;
  closeCallback?: () => void;
  [key: string]: any;
}

export const alertDialogManager = proxy({
  open: false,
  details: {} as AlertDialogManagerDetails,
});

export const openAlertDialog = <T extends AlertDialogManagerDetails>(details?: T) => {
  alertDialogManager.open = true;
  if (details !== undefined) {
    alertDialogManager.details = details;
  }
};

export const closeAlertDialog = () => {
  alertDialogManager.open = false;
  alertDialogManager.details = {};
};
