import { RequiringActionType } from '@paid-ui/constants';
import { featureManager } from '@paid-ui/models/feature';
import { requiringActionManager, saveRequiringActions } from '@paid-ui/models/requiring-action';
import { type RequiringAction } from '@paid-ui/types/requiring-actions';
import { request } from '@paid-ui/utils/axios';
import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useSnapshot } from 'valtio/react';

type GetRequiringActionsResponse = {
  data: {
    requiredActions: RequiringAction[];
  };
} | null;

export const useGetRequiringActions = () => {
  const { forceUpdate } = useSnapshot(requiringActionManager);
  const { enableTransactionAccount } = useSnapshot(featureManager);

  const query = useQuery({
    refetchOnWindowFocus: true,
    refetchInterval: 15_000,
    queryKey: ['GetRequiringActions', enableTransactionAccount],
    queryFn: async () => {
      const { data } = await request.get<GetRequiringActionsResponse>('/ras/actions');
      const allRequiringActions = data?.data.requiredActions ?? [];
      return allRequiringActions.filter(
        ({ action }) =>
          enableTransactionAccount ||
          (!enableTransactionAccount && action !== RequiringActionType.PAY),
      );
    },
  });

  useEffect(() => {
    const { isFetching, refetch } = query;
    if (isFetching) return;
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forceUpdate]);

  useEffect(() => {
    saveRequiringActions(query.data ?? []);
  }, [query.data]);

  return query;
};
