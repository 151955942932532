import { userManager } from '@paid-ui/models/user';
import { twMerge } from 'tailwind-merge';
import { useSnapshot } from 'valtio/react';

type Props = {
  fullWidth?: boolean;
  children: React.ReactNode;
};

export const Navbar: React.FC<Props> = ({ fullWidth, children }) => {
  const { impersonation } = useSnapshot(userManager);

  return (
    <header
      className={twMerge(
        'sticky inset-x-0 top-0 z-50 h-[54px] w-full shrink-0 shadow',
        impersonation ? 'bg-purple' : 'bg-black',
      )}
    >
      <div
        className={twMerge(
          'container mx-auto flex h-full items-center justify-between',
          fullWidth ? 'max-w-full' : '',
        )}
      >
        {children}
      </div>
    </header>
  );
};
