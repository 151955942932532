import { Avatar, type AvatarProps } from '@paid-ui/components/avatar';
import { userManager } from '@paid-ui/models/user';
import { forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';
import { useSnapshot } from 'valtio/react';

export const UserAvatar = forwardRef<HTMLDivElement, AvatarProps>(
  ({ className, ...restProps }, ref) => {
    const { profile } = useSnapshot(userManager);
    const fallback = profile ? `${profile.firstName.at(0)}${profile.lastName.at(0)}` : null;

    return (
      <Avatar
        className={twMerge(
          'h-8 w-8 border-2 border-white bg-blue text-white transition-colors duration-150 ease-linear hover:bg-blue-dark',
          className,
        )}
        {...restProps}
      >
        {fallback}
      </Avatar>
    );
  },
);

UserAvatar.displayName = 'UserAvatar';
