import {
  BottomDrawerContent,
  BottomDrawerOverlay,
  BottomDrawerPortal,
  BottomDrawerRoot,
  BottomDrawerTitle,
  BottomDrawerTrigger,
} from '@paid-ui/components/bottom-drawer';
import {
  DropdownMenuContent,
  DropdownMenuPortal,
  DropdownMenuRoot,
  DropdownMenuTrigger,
} from '@paid-ui/components/dropdown-menu';
import { requiringActionSearchParamMap } from '@paid-ui/constants/requiring-actions';
import { ClaimMethod } from '@paid-ui/enums/claim';
import { ContractTemplate } from '@paid-ui/enums/contract';
import { useMediaQuery } from '@paid-ui/hooks/use-media-query';
import { BellIcon } from '@paid-ui/icons/bell';
import { featureManager } from '@paid-ui/models/feature';
import { setCurrentPayment } from '@paid-ui/models/payment';
import { requiringActionManager } from '@paid-ui/models/requiring-action';
import { useDismissRequiringAction } from '@paid-ui/mutations/dismiss-requiring-action';
import { useGetRequiringActions } from '@paid-ui/queries/requiring-actions';
import { type RequiringAction, type RequiringActionName } from '@paid-ui/types/requiring-actions';
import { RequiringActions } from '@paid-ui/ui/requiring-actions';
import { useToast } from '@paid-ui/ui/toast';
import { nowMel, utc2Mel } from '@paid-ui/utils/datetime';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useSnapshot } from 'valtio/react';

import { NavbarIconButton } from '../navbar-button';

const CountIndicator: React.FC<{ count: number }> = ({ count }) => {
  if (!count) {
    return null;
  }

  return (
    <span className="absolute right-[-4px] top-[-2px] inline-flex h-[18px] w-[18px] items-center justify-center rounded-lg bg-pink pb-0.5 text-[10px] font-medium leading-none text-white shadow-sm">
      {Math.min(count, 99)}
    </span>
  );
};

export const NotificationCenter: React.FC = () => {
  const toast = useToast();
  const router = useRouter();
  const [open, setOpen] = useState(false);
  const { enablePeriodicContracts } = useSnapshot(featureManager);
  const { requiringActions } = useSnapshot(requiringActionManager);
  const { refetch } = useGetRequiringActions();
  const { mutate, isLoading } = useDismissRequiringAction();
  const smallScreen = useMediaQuery('(max-width: 640px)');
  const filteredRequiringActions = requiringActions.filter(({ visibleFrom }) =>
    visibleFrom ? nowMel().isAfter(utc2Mel(visibleFrom)) : true,
  );

  const handleRequiringActionDismiss = (action: RequiringAction) => {
    mutate({ requiredActionId: action.id });
  };

  const handleRequiringActionClick = (action: RequiringAction) => {
    setOpen(false);

    const targetContractId = action.data?.contractId;
    const targetProjectId = action.data?.projectId;
    const partyId = action.data?.partyId;
    const actionName = `${action.action}_${action.featureItemType}` as RequiringActionName;
    const dialogSearchParam = requiringActionSearchParamMap[actionName];

    if (targetContractId) {
      if (targetProjectId) {
        const urlSearchParams = new URLSearchParams();

        urlSearchParams.set('contractId', targetContractId);
        urlSearchParams.set('action', action.id);

        if (dialogSearchParam) {
          urlSearchParams.set('dialog', dialogSearchParam);
        }

        switch (actionName) {
          case 'REVIEW_CONTRACT':
          case 'UPDATE_CONTRACT': {
            if (action.data?.contractType) {
              urlSearchParams.set('contractType', action.data.contractType.toLowerCase());
            }
            if (action.data?.paymentStructure) {
              urlSearchParams.set('method', action.data.paymentStructure.toLowerCase());
            }
            if (action.data?.contractTemplate) {
              if (
                action.data?.contractTemplate === ContractTemplate.SUPER_CONTRACT &&
                actionName === 'REVIEW_CONTRACT'
              ) {
                urlSearchParams.set('dialog', 'review-super-contract');
                break;
              }
              urlSearchParams.set('template', action.data.contractTemplate.toLowerCase());
            }
            break;
          }
          case 'SUBMIT_CLAIM': {
            urlSearchParams.set('provisional', '1');
            if (action.data?.paymentStructure) {
              urlSearchParams.set('method', action.data.paymentStructure.toLowerCase());
            }
            break;
          }
          case 'SUBMIT_CLAIM_CONTRACT': {
            if (action.data?.paymentStructure) {
              urlSearchParams.set('method', action.data.paymentStructure.toLowerCase());
            }
            break;
          }

          case 'SIGN_CONTRACT': {
            if (action.data?.contractTemplate === ContractTemplate.SUPER_CONTRACT) {
              urlSearchParams.set('contractTemplate', action.data.contractTemplate);
            } else if (partyId) {
              urlSearchParams.set('partyId', partyId ?? '');
            }
            break;
          }

          case 'REVIEW_CLAIM':
          case 'UPDATE_CLAIM': {
            if (action.data?.progressPaymentId) {
              setCurrentPayment(action.data.progressPaymentId);
              urlSearchParams.set('paymentId', action.data.progressPaymentId);
            }

            if (action.data?.paymentStructure === ClaimMethod.PERIODIC) {
              if (enablePeriodicContracts) {
                urlSearchParams.set('method', ClaimMethod.PERIODIC.toLowerCase());
              } else {
                toast.error(
                  'You have to enable periodic contract feature to review/submit this claim.',
                );
                router.replace(window.location.pathname);
                return;
              }
            } else {
              urlSearchParams.set('method', ClaimMethod.STAGED.toLowerCase());
            }
            break;
          }

          case 'PAY_CLAIM': {
            urlSearchParams.set('dialog', 'pay-claim');
            urlSearchParams.set('claimId', action.featureItemId);
            break;
          }

          case 'REVIEW_ADJUSTMENT':
          case 'UPDATE_ADJUSTMENT': {
            urlSearchParams.set('adjustment', action.featureItemId);
            break;
          }

          case 'REVIEW_VARIATION':
          case 'UPDATE_VARIATION': {
            urlSearchParams.set('variation', action.featureItemId);

            if (action.data?.paymentStructure === ClaimMethod.PERIODIC) {
              if (enablePeriodicContracts) {
                urlSearchParams.set('method', ClaimMethod.PERIODIC.toLowerCase());
              } else {
                toast.error(
                  'You have to enable periodic contract feature to review/submit this variation.',
                );
                router.replace(window.location.pathname);
                return;
              }
            } else {
              urlSearchParams.set('method', ClaimMethod.STAGED.toLowerCase());
            }
            break;
          }

          case 'REVIEW_EARLY_RELEASE_REQUEST': {
            if (action.data?.claimId) {
              urlSearchParams.set('claimId', action.data.claimId);
            }
            break;
          }

          default: {
            break;
          }
        }

        urlSearchParams.sort();
        router.replace(
          [`/projects/${targetProjectId}`, urlSearchParams.toString()].join('?'),
          undefined,
          {
            shallow: true,
          },
        );
      } else if (dialogSearchParam === 'complete-contract') {
        const urlSearchParams = new URLSearchParams();
        urlSearchParams.set('action', action.id);
        urlSearchParams.set('dialog', dialogSearchParam);
        urlSearchParams.set('contractId', targetContractId);
        if (partyId) {
          urlSearchParams.set('partyId', partyId);
        }
        if (action.data?.contractType) {
          urlSearchParams.set('contractType', action.data.contractType.toLowerCase());
        }
        if (action.data?.contractTemplate) {
          urlSearchParams.set('template', action.data.contractTemplate.toLowerCase());
        }
        if (action.data?.paymentStructure) {
          urlSearchParams.set('method', action.data.paymentStructure.toLowerCase());
          if (action.data.paymentStructure === ClaimMethod.PERIODIC) {
            if (enablePeriodicContracts) {
              urlSearchParams.set('dialog', 'join-contract');
            } else {
              toast.error('You have to enable periodic contract feature to join this contract.');
              router.replace('/');
              return;
            }
          }
        }
        urlSearchParams.sort();
        router.replace({
          pathname: '/',
          search: urlSearchParams.toString(),
        });
      }
    }
  };

  useEffect(() => {
    if (open) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  if (smallScreen) {
    return (
      <BottomDrawerRoot open={open} onOpenChange={setOpen} shouldScaleBackground={false}>
        <BottomDrawerTrigger asChild>
          <NavbarIconButton>
            <BellIcon className="h-6 w-6" />
            <CountIndicator count={filteredRequiringActions.length} />
          </NavbarIconButton>
        </BottomDrawerTrigger>
        <BottomDrawerPortal>
          <BottomDrawerOverlay />
          <BottomDrawerContent>
            <div className="flex flex-1 flex-col overflow-y-auto overflow-x-hidden">
              <header className="shrink-0">
                <BottomDrawerTitle asChild>
                  <h2 className="px-5 pb-[18px] pt-3.5 text-center text-base font-medium">
                    Requiring action
                  </h2>
                </BottomDrawerTitle>
              </header>
              <div className="flex-1 overflow-y-auto">
                <RequiringActions
                  groupByDate
                  data={filteredRequiringActions}
                  disabled={isLoading}
                  onItemClick={handleRequiringActionClick}
                  onItemDismiss={handleRequiringActionDismiss}
                />
              </div>
            </div>
          </BottomDrawerContent>
        </BottomDrawerPortal>
      </BottomDrawerRoot>
    );
  }

  return (
    <DropdownMenuRoot open={open} onOpenChange={setOpen}>
      <DropdownMenuTrigger asChild>
        <NavbarIconButton>
          <BellIcon className="h-6 w-6" />
          <CountIndicator count={filteredRequiringActions.length} />
        </NavbarIconButton>
      </DropdownMenuTrigger>
      <DropdownMenuPortal>
        <DropdownMenuContent className="relative z-[400] flex max-h-[calc(100vh-74px)] flex-col gap-y-4 overflow-y-auto overflow-x-hidden px-4 pb-4">
          <header className="sticky top-[-4px] shrink-0 border-b border-blue bg-white py-4">
            <h2 className="text-center text-base font-medium text-blue">Requiring action</h2>
          </header>
          <div className="flex-1">
            <RequiringActions
              groupByDate
              data={filteredRequiringActions}
              disabled={isLoading}
              onItemClick={handleRequiringActionClick}
              onItemDismiss={handleRequiringActionDismiss}
            />
          </div>
        </DropdownMenuContent>
      </DropdownMenuPortal>
    </DropdownMenuRoot>
  );
};
