import * as SeparatorPrimitive from '@radix-ui/react-separator';
import { cva } from 'class-variance-authority';
import { forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

const separatorVariants = cva(
  'bg-grey-mid data-[orientation=horizontal]:h-[1px] data-[orientation=vertical]:h-auto data-[orientation=horizontal]:w-full data-[orientation=vertical]:w-[1px]',
);

export type SeparatorProps = SeparatorPrimitive.SeparatorProps;

export const Separator = forwardRef<HTMLDivElement, SeparatorProps>((props, ref) => {
  const { decorative = true, orientation = 'horizontal', hidden, className, ...restProps } = props;

  if (hidden) {
    return null;
  }

  return (
    <SeparatorPrimitive.Root
      ref={ref}
      decorative={decorative}
      orientation={orientation}
      className={twMerge(separatorVariants({ className }))}
      {...restProps}
    />
  );
});

Separator.displayName = 'Separator';
