import { EvidencePreview } from '@paid-ui/blocks/evidence-preview';
import { GlobalAlertDialog } from '@paid-ui/blocks/global-alert';
import { GlobalConfirmDialog } from '@paid-ui/blocks/global-confirm';
import { GlobalDialog } from '@paid-ui/blocks/global-dialog';
import { GlobalFlyout } from '@paid-ui/blocks/global-flyout';
import { Logo } from '@paid-ui/components/logo';
import { UnstyledButton } from '@paid-ui/components/unstyled-button';
import { appConfigManager, getMaintenance } from '@paid-ui/models/app-config';
import { featureManager } from '@paid-ui/models/feature';
import { userManager } from '@paid-ui/models/user';
import { userGroupManager } from '@paid-ui/models/user-group';
import { useRetentionAccountSetup } from '@paid-ui/queries/retention-account';
import { useTransactionAccountSetup } from '@paid-ui/queries/transaction-account';
import { Banner, Flex, styled } from '@paid-ui/ui';
import { useSnapshot } from 'valtio/react';

import { HamburgerMenu } from './hamburger-menu';
import { HelpCenter } from './help-center';
import { Navbar } from './navbar';
import { NavbarLink } from './navbar-link';
import { NotificationCenter } from './notification-center';
import { UserCenter } from './user-center/UserCenter';

export const BannerContentContainer = styled(Flex, {
  'color': 'white',
  'alignItems': 'start',
  'flexDirection': 'column',
  'gap': '8px',
  '@md': {
    gap: '18px',
    alignItems: 'center',
    flexDirection: 'unset',
  },
});

type Props = {
  fullWidthNav?: boolean;
  children: React.ReactNode;
  className?: string;
};

export const BasicLayout: React.FC<Props> = ({ fullWidthNav, children, className }) => {
  const { announcements } = useSnapshot(appConfigManager);
  const { enableTransactionAccount, enableClaimsTab, enableOnPlatformRetention } =
    useSnapshot(featureManager);
  const {
    permission: { canManagePayment },
  } = useSnapshot(userGroupManager);
  const { isOwner, isAccountReady } = useSnapshot(userManager);
  const maintenance = getMaintenance(announcements);
  const enableAccount = enableTransactionAccount && isAccountReady;

  useTransactionAccountSetup(enableAccount);
  useRetentionAccountSetup(enableAccount && enableOnPlatformRetention);

  return (
    <>
      <Navbar fullWidth={fullWidthNav}>
        <div className="flex items-center gap-x-6">
          <UnstyledButton as="a" href="/" className="shrink-0">
            <Logo width={30} symbolOnly />
          </UnstyledButton>
          <HamburgerMenu />
          <nav className="mr-auto hidden h-8 flex-1 items-center gap-x-6 sm:flex">
            <NavbarLink href="/">Dashboard</NavbarLink>
            <NavbarLink href="/claims" hidden={!enableClaimsTab || isOwner || !canManagePayment}>
              Claims
            </NavbarLink>
            <NavbarLink href="/account" hidden={!enableTransactionAccount || !canManagePayment}>
              Account
            </NavbarLink>
          </nav>
        </div>
        <div className="flex shrink-0 items-center gap-x-6">
          <HelpCenter />
          <NotificationCenter />
          <UserCenter />
        </div>
      </Navbar>

      <Banner
        hidden={!maintenance}
        text={
          <BannerContentContainer>
            <span>
              Scheduled maintenance planned: {maintenance?.startDate} - {maintenance?.endDate} AEST
            </span>
            <span style={{ fontSize: '14px' }}>
              Paid platform including access to transaction account will be unavailable during this
              period
            </span>
          </BannerContentContainer>
        }
      />

      <GlobalDialog />
      <GlobalFlyout />
      <GlobalAlertDialog />
      <GlobalConfirmDialog />
      <EvidencePreview />
      {children}
    </>
  );
};
