import * as AvatarPrimitive from '@radix-ui/react-avatar';
import { cva, type VariantProps } from 'class-variance-authority';
import { forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

import { getShortName } from './utils';

const AvatarRoot = AvatarPrimitive.Root;
const AvatarImage = AvatarPrimitive.Image;
const AvatarFallback = AvatarPrimitive.Fallback;

const avatarVariants = cva(
  'inline-flex select-none items-center justify-center overflow-hidden bg-grey-mid align-middle transition-colors duration-150 ease-linear',
  {
    variants: {
      size: {
        xs: 'h-[30px] w-[30px] text-xs',
        sm: 'h-[36px] w-[36px] text-sm',
        md: 'h-[42px] w-[42px] text-base',
        lg: 'h-[50px] w-[50px] text-lg',
        xl: 'h-[60px] w-[60px] text-xl',
      },
      radius: {
        none: 'rounded-none',
        xs: 'rounded-sm',
        sm: 'rounded-[4px]',
        md: 'rounded-md',
        lg: 'rounded-lg',
        xl: 'rounded-xl',
        full: 'rounded-full',
      },
    },
  },
);

export interface AvatarProps
  extends VariantProps<typeof avatarVariants>,
    Omit<React.HTMLAttributes<HTMLSpanElement>, 'size'> {
  src?: string;
  alt?: string;
  fallbackDelayMs?: number;
}

export const Avatar = forwardRef<HTMLSpanElement, AvatarProps>(
  (
    {
      size = 'sm',
      radius = 'full',
      src,
      alt,
      hidden,
      fallbackDelayMs = 500,
      children,
      className,
      ...restProps
    },
    ref,
  ) => {
    if (hidden) {
      return null;
    }

    return (
      <AvatarRoot
        ref={ref}
        className={twMerge(
          avatarVariants({
            size,
            radius,
            className,
          }),
        )}
        {...restProps}
      >
        <AvatarImage src={src} alt={alt} className="h-full w-full object-cover" />
        <AvatarFallback delayMs={fallbackDelayMs} className="font-medium">
          {typeof children === 'string' ? getShortName(children) : children}
        </AvatarFallback>
      </AvatarRoot>
    );
  },
);

Avatar.displayName = 'Avatar';
