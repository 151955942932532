export const DashboardIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 25 24"
      width="1em"
      height="1em"
      fill="currentColor"
      {...props}
    >
      <path d="M19.084 3H5.08398C3.98398 3 3.08398 3.9 3.08398 5V19C3.08398 20.1 3.98398 21 5.08398 21H19.084C20.184 21 21.084 20.1 21.084 19V5C21.084 3.9 20.184 3 19.084 3ZM5.08398 19V5H11.084V19H5.08398ZM19.084 19H13.084V12H19.084V19ZM19.084 10H13.084V5H19.084V10Z" />
    </svg>
  );
};
