import { Spinner, styled } from '@paid-ui/ui';

const Container = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
});

const LoadingIndicator: React.FC = () => {
  return (
    <Container>
      <Spinner size="lg" color="$blue" emptyColor="$fgFaint" />
    </Container>
  );
};

export default LoadingIndicator;
