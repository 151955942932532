import { type Evidence } from '@paid-ui/schemas/zod/evidence';
import { findIndex, findLastIndex } from 'lodash';
import { proxy } from 'valtio';
import { devtools } from 'valtio/utils';

export enum PreviewType {
  PHOTO = 'PHOTO',
  VIDEO = 'VIDEO',
}

export const initialState = {
  open: false,
  type: PreviewType.PHOTO,
  current: 0,
  data: [] as Evidence[],
  className: '',
  closeCallback: () => {},
};

export const evidencePreviewManager = proxy(initialState);

devtools(evidencePreviewManager, {
  name: 'Global Evidence Preview',
  enabled: false,
});

export const closeEvidencePreview = () => {
  evidencePreviewManager.open = false;
};

export const openEvidencePreview = (
  evidence: Evidence,
  details?: Partial<Omit<typeof initialState, 'open'>>,
) => {
  Object.assign(evidencePreviewManager, initialState, {
    open: true,
    type: evidence.fileType?.startsWith('video/') ? PreviewType.VIDEO : PreviewType.PHOTO,
    current: 0,
    data: [evidence],
    ...details,
  });
};

export const openEvidencesPreview = (
  evidences: Evidence[],
  details?: Partial<Omit<typeof initialState, 'open'>>,
) => {
  const currentEvidence = evidences[details?.current ?? 0];
  if (!currentEvidence) return;
  Object.assign(evidencePreviewManager, initialState, {
    open: true,
    type: currentEvidence.fileType?.startsWith('video/') ? PreviewType.VIDEO : PreviewType.PHOTO,
    data: evidences,
    ...details,
  });
};

export const saveEvidences = (
  evidences: Evidence[],
  details?: Partial<Omit<typeof initialState, 'open'>>,
) => {
  const currentEvidence = evidences[details?.current ?? 0];
  if (!currentEvidence) return;
  Object.assign(evidencePreviewManager, initialState, {
    open: false,
    type: currentEvidence.fileType?.startsWith('video/') ? PreviewType.VIDEO : PreviewType.PHOTO,
    data: evidences,
    ...details,
  });
};

export const prevEvidence = (options?: { skipNoGeolocation?: boolean }) => {
  const { skipNoGeolocation } = options ?? {};
  const { current, data } = evidencePreviewManager;
  let prevIndex = current - 1;

  if (skipNoGeolocation) {
    const foundIndex = findLastIndex(
      data,
      (v, i) => i < current && Boolean(v.geolocation?.geolocatable),
      current,
    );
    if (foundIndex === -1) return;
    prevIndex = foundIndex;
  }

  prevIndex = Math.max(0, prevIndex);

  const prevEvidence = data[prevIndex];
  if (!prevEvidence) return;
  evidencePreviewManager.type = prevEvidence.fileType?.startsWith('video/')
    ? PreviewType.VIDEO
    : PreviewType.PHOTO;
  evidencePreviewManager.current = prevIndex;
};

export const nextEvidence = (options?: { skipNoGeolocation?: boolean }) => {
  const { skipNoGeolocation } = options ?? {};
  const { data, current } = evidencePreviewManager;
  let nextIndex = current + 1;

  if (skipNoGeolocation) {
    const foundIndex = findIndex(
      data,
      (v, i) => i > current && Boolean(v.geolocation?.geolocatable),
      current,
    );
    if (foundIndex === -1) return;
    nextIndex = foundIndex;
  }

  nextIndex = Math.min(nextIndex, data.length - 1);

  const nextEvidence = data[nextIndex];
  if (!nextEvidence) return;
  evidencePreviewManager.type = nextEvidence.fileType?.startsWith('video/')
    ? PreviewType.VIDEO
    : PreviewType.PHOTO;
  evidencePreviewManager.current = nextIndex;
};
