export const LocationIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 18 19"
      width="1em"
      height="1em"
      fill="currentColor"
      {...props}
    >
      <path d="M9 0.5C7.01088 0.5 5.10322 1.29018 3.6967 2.6967C2.29018 4.10322 1.5 6.01088 1.5 8C1.5 13.5875 8.295 18.18 8.585 18.375C8.70799 18.4567 8.85235 18.5003 9 18.5003C9.14765 18.5003 9.29201 18.4567 9.415 18.375C9.705 18.18 16.5 13.5875 16.5 8C16.5 7.01509 16.306 6.03982 15.9291 5.12987C15.5522 4.21993 14.9997 3.39314 14.3033 2.6967C13.6069 2.00026 12.7801 1.44781 11.8701 1.0709C10.9602 0.693993 9.98491 0.5 9 0.5V0.5ZM9 11C8.40666 11 7.82664 10.8241 7.33329 10.4944C6.83994 10.1648 6.45542 9.69623 6.22836 9.14805C6.0013 8.59987 5.94189 7.99667 6.05764 7.41473C6.1734 6.83279 6.45912 6.29824 6.87868 5.87868C7.29824 5.45912 7.83279 5.1734 8.41473 5.05764C8.99667 4.94189 9.59987 5.0013 10.1481 5.22836C10.6962 5.45542 11.1648 5.83994 11.4944 6.33329C11.8241 6.82664 12 7.40666 12 8C12 8.79565 11.6839 9.55871 11.1213 10.1213C10.5587 10.6839 9.79565 11 9 11Z" />
      <path
        opacity="0.35"
        d="M9 11C10.6569 11 12 9.65685 12 8C12 6.34315 10.6569 5 9 5C7.34315 5 6 6.34315 6 8C6 9.65685 7.34315 11 9 11Z"
      />
    </svg>
  );
};
