import { UnstyledButton, type UnstyledButtonProps } from '@paid-ui/components/unstyled-button';
import { cloneElement, forwardRef, isValidElement } from 'react';
import { twMerge } from 'tailwind-merge';

export const NavbarIconButton = forwardRef<HTMLButtonElement, UnstyledButtonProps>(
  ({ children, className, ...restProps }, ref) => {
    return (
      <UnstyledButton
        ref={ref}
        className={twMerge(
          'relative inline-flex h-8 w-8 shrink-0 cursor-pointer items-center justify-center text-white/60 no-underline transition-colors duration-150 ease-linear hover:text-white focus:text-white',
          className,
        )}
        {...restProps}
      >
        {isValidElement<React.SVGAttributes<SVGElement>>(children)
          ? cloneElement(children, {
              ...children.props,
              'aria-hidden': 'true',
              'className': twMerge('h-6 w-6', children?.props?.className),
            })
          : children}
      </UnstyledButton>
    );
  },
);

NavbarIconButton.displayName = 'NavbarIconButton';
