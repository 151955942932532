import { DropdownMenu, type MenuItem } from '@paid-ui/components/dropdown-menu';
import { UnstyledButton } from '@paid-ui/components/unstyled-button';
import { appConfigManager } from '@paid-ui/models/app-config';
import { openFeatureToggleDialog } from '@paid-ui/models/dialog';
import { userManager } from '@paid-ui/models/user';
import { useSignOut } from '@paid-ui/mutations/sign-out';
import { useSnapshot } from 'valtio/react';

import { UserAvatar } from '../user-avatar';

export const UserCenter: React.FC = () => {
  const { profile } = useSnapshot(userManager);
  const { env } = useSnapshot(appConfigManager);
  const { mutate, isLoading: isPending } = useSignOut();
  const userName = [profile?.firstName, profile?.lastName].filter(Boolean).join(' ');

  const menuItems: MenuItem[] = [
    {
      type: 'item',
      hoverable: false,
      title: (
        <>
          <div>
            Signed as <strong>{userName}</strong>
          </div>
          <div className="empty:hidden">{profile?.email}</div>
        </>
      ),
    },
    {
      type: 'separator',
    },
    {
      type: 'item',
      title: 'Profile and settings',
      href: '/settings',
      className: 'text-base',
    },
    {
      type: 'item',
      title: 'Feature toggle',
      hidden: ['uat', 'prod'].includes(env),
      className: 'text-base',
      onSelect: () => openFeatureToggleDialog(),
    },
    {
      type: 'item',
      title: 'Sign out',
      danger: true,
      disabled: isPending,
      className: 'text-base',
      onSelect: () => mutate(),
    },
  ];

  return (
    <DropdownMenu items={menuItems} asChild>
      <UnstyledButton className="hidden shrink-0 cursor-pointer sm:inline-block">
        <UserAvatar />
      </UnstyledButton>
    </DropdownMenu>
  );
};
