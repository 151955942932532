import { FlyoutType, openFlyout } from '@paid-ui/blocks/global-flyout';
import { EmailIcon } from '@paid-ui/icons/email';

import { NavbarIconButton } from '../navbar-button';

export const HelpCenter: React.FC = () => {
  return (
    <NavbarIconButton
      onClick={() =>
        openFlyout(FlyoutType.HELP_CENTER, {
          className: 'w-[752px]',
          showCloseIcon: true,
          closeOnClickOutside: true,
        })
      }
    >
      <EmailIcon className="text-tertiary" />
    </NavbarIconButton>
  );
};
