export const BellIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="1em"
      height="1em"
      fill="currentColor"
      {...props}
    >
      <path d="M21.0001 18H3.00007C2.7852 18.0001 2.57601 17.931 2.40348 17.803C2.23094 17.6749 2.10425 17.4947 2.04217 17.2889C1.98008 17.0832 1.9859 16.863 2.05877 16.6609C2.13164 16.4587 2.26768 16.2854 2.44674 16.1667C2.44674 16.1667 5.00007 14.27 5.00007 8C5.00007 6.14348 5.73757 4.36301 7.05033 3.05025C8.36308 1.7375 10.1436 1 12.0001 1C13.8566 1 15.6371 1.7375 16.9498 3.05025C18.2626 4.36301 19.0001 6.14348 19.0001 8C19.0001 14.27 21.5434 16.16 21.5667 16.1767C21.7416 16.2989 21.8729 16.4738 21.9412 16.6759C22.0096 16.878 22.0115 17.0967 21.9467 17.3C21.8833 17.5018 21.7575 17.6783 21.5873 17.8041C21.4172 17.9299 21.2116 17.9985 21.0001 18V18ZM5.14007 16H18.8601C17.9567 14.5167 17.0001 12 17.0001 8C17.0001 6.67392 16.4733 5.40215 15.5356 4.46447C14.5979 3.52678 13.3262 3 12.0001 3C10.674 3 9.40222 3.52678 8.46454 4.46447C7.52686 5.40215 7.00007 6.67392 7.00007 8C7.00007 12 6.04674 14.5167 5.14007 16Z" />
      <path d="M11.9999 23C11.4759 22.9987 10.9614 22.8601 10.5075 22.5981C10.0537 22.3361 9.67644 21.9598 9.41326 21.5067C9.30152 21.2792 9.2803 21.0179 9.35387 20.7754C9.42745 20.5329 9.59034 20.3274 9.80962 20.2004C10.0289 20.0734 10.2882 20.0344 10.5352 20.0912C10.7821 20.1481 10.9983 20.2966 11.1399 20.5067C11.2282 20.6578 11.3547 20.7831 11.5066 20.87C11.736 21.003 12.0089 21.0395 12.2652 20.9714C12.5215 20.9032 12.7402 20.7361 12.8733 20.5067C12.9337 20.3837 13.0188 20.2744 13.1232 20.1856C13.2277 20.0968 13.3493 20.0305 13.4804 19.9907C13.6116 19.9509 13.7496 19.9385 13.8857 19.9543C14.0219 19.97 14.1533 20.0136 14.272 20.0823C14.3906 20.151 14.4938 20.2433 14.5752 20.3536C14.6567 20.4638 14.7145 20.5897 14.7453 20.7233C14.7761 20.8568 14.779 20.9953 14.754 21.1301C14.729 21.2648 14.6766 21.393 14.5999 21.5067C14.3356 21.9618 13.9562 22.3393 13.4999 22.6015C13.0435 22.8636 12.5262 23.001 11.9999 23Z" />
    </svg>
  );
};
