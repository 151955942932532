export const ZoomOutIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="1em"
      height="1em"
      fill="none"
      stroke="currentColor"
      {...props}
    >
      <path d="M21 12H3" strokeWidth="2" strokeLinecap="round" />
    </svg>
  );
};
