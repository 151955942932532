import { proxy, subscribe } from 'valtio';
import { subscribeKey } from 'valtio/utils';

const initialState = {
  useLocalConfigs: false,
  useLocalSnapshots: false,
};

export const devtoolsManager = proxy<typeof initialState>({
  useLocalConfigs: JSON.parse(
    globalThis?.sessionStorage?.getItem('use_local_configs') ?? 'false',
  ) as boolean,
  useLocalSnapshots: JSON.parse(
    globalThis?.sessionStorage?.getItem('use_local_snapshots') ?? 'false',
  ) as boolean,
});

export const resetDevtoolsManager = () => {
  Object.assign(devtoolsManager, initialState);
};

export const setUseLocalConfigs = (value: boolean) => {
  devtoolsManager.useLocalConfigs = value;
};

export const setUseLocalSnapshots = (value: boolean) => {
  devtoolsManager.useLocalSnapshots = value;
};

subscribeKey(devtoolsManager, 'useLocalConfigs', (value) => {
  globalThis?.sessionStorage?.setItem('use_local_configs', String(value));
});

subscribeKey(devtoolsManager, 'useLocalSnapshots', (value) => {
  globalThis?.sessionStorage?.setItem('use_local_snapshots', String(value));
});
