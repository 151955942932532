import { reloadRequiringActions } from '@paid-ui/models/requiring-action';
import { useToast } from '@paid-ui/ui/toast';
import { request } from '@paid-ui/utils/axios';
import { useMutation } from '@tanstack/react-query';

type DismissRequiringActionParams = {
  requiredActionId: string;
};

export const useDismissRequiringAction = () => {
  const toast = useToast();
  return useMutation({
    mutationKey: ['DismissRequiringAction'],
    mutationFn: async (params: DismissRequiringActionParams) => {
      const { requiredActionId } = params;
      await request.post<null>(`/require-action/${requiredActionId}/dismiss`);
      return null;
    },
    onSuccess: () => {
      toast.success('Requiring action dismissed.');
      reloadRequiringActions();
    },
    onError: (error) => {
      toast.error(error instanceof Error ? error.message : 'Failed to dismiss requiring action.');
    },
  });
};
