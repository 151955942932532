/**
 * Get short name from full name
 *
 * @author Ritchie Zhu <fengfu.zhu@paid.inc>
 * @date 2023-05-14
 * @param fullName - Full name
 * @returns Short name
 */
export function getShortName(fullName: string) {
  if (!fullName) {
    return 'UN';
  }
  const [firstName, lastName] = fullName.split(' ');
  if (!firstName) {
    return 'UN';
  }
  if (!lastName) {
    return firstName.slice(0, 2).toUpperCase();
  }
  return `${firstName.slice(0, 1)}${lastName.slice(0, 1)}`.toUpperCase();
}
