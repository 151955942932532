import {
  type ClaimLinkStatus,
  type ClearingState,
  defaultAddress,
  type InternalPaymentMethod,
  type SubType,
  TransactionHistoryType,
} from '@paid-ui/constants';
import { type TransactionHistory } from '@paid-ui/types/account';
import { proxy } from 'valtio';
import { devtools } from 'valtio/utils';

const initialState: TransactionHistory = {
  id: '',
  transactionNumber: '',
  origin: '',
  projectName: '',
  date: '',
  credit: 0,
  debit: 0,
  type: TransactionHistoryType.UNKNOWN,
  subType: '' as SubType,
  address: defaultAddress,
  claimLinkStatus: '' as ClaimLinkStatus,
  clearingState: '' as ClearingState,
  description: '',
  displayName: '',
  referenceTransactionId: '',
  transactionFee: 0,
  transactionFeeType: TransactionHistoryType.UNKNOWN,
  transactionFeeOrigin: '',
  transactionFeeDescription: '',
  linkedContracts: null,
  alreadyPaidMatchedClaim: null,
  theOtherPartyName: '',
  toAccount: '',
  toBsb: '',
  internalPaymentMethod: '' as InternalPaymentMethod,
  earlyReleaseDiscount: 0,
};

export const transactionHistoryManager = proxy(initialState);

devtools(transactionHistoryManager, {
  name: 'Current Transaction History',
  enabled: false,
});

export const resetTransactionHistory = () => {
  Object.assign(transactionHistoryManager, initialState);
};

export const saveCurrentTransactionHistory = (payload: TransactionHistory) => {
  Object.assign(transactionHistoryManager, initialState, payload);
};
